<template>
  <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y :left="left"
    :right="right" :bottom="bottom" :top="top">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field v-model="dateTime" :value="value" :data-layout="dataLayout" :clearable="clearable"
        @click:clear="clear" @input="menu=false" @focus="$emit('focus')" :rules="[isValideDateTime, ...rules]"
        :label="label" :disabled="disabled" :readonly="readonly" :outlined="outlined" :dense="dense"
        :prepend-inner-icon="prependInnerIcon" :class="customClass" v-bind="attrs" v-on="on" />
    </template>

    <v-card>
      <v-card-text class="d-flex align-center justify-center" :class="$vuetify.breakpoint.xs?'flex-column':'flex-row'">
        <v-date-picker v-if="menu" v-model="date" :max="max" :min="min" :show-current="showCurrent" :no-title="noTitle"
          :scrollable="scrollable" :type="type">
        </v-date-picker>
        <v-time-picker v-if="menu" v-model="time" :no-title="noTitle" :scrollable="scrollable" :disabled="!date"
          format="24hr">
        </v-time-picker>
      </v-card-text>
      <!-- in case of mobile ysage -->
      <!-- <template v-if="$vuetify.breakpoint.mdAndUp">

      </template>
      <div v-else>
        <v-tabs v-model="tab" grow>
          <v-tab>{{ $t('generic.lang_date') }}</v-tab>
          <v-tab>{{ $t('generic.lang_time') }}</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <div class="d-flex justify-center">
              <v-date-picker v-model="date" :max="max" :min="min" :show-current="showCurrent" :no-title="noTitle"
                :scrollable="scrollable" :type="type">
              </v-date-picker>
            </div>
          </v-tab-item>
          <v-tab-item>
            <div class="d-flex justify-center">
              <v-time-picker v-model="time" :no-title="noTitle" :scrollable="scrollable" :disabled="!date"
                format="24hr">
              </v-time-picker>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </div> -->

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="close()">
          {{ $t("generic.lang_cancel") }}
        </v-btn>
        <v-btn text color="primary" :disabled="date === '' || time == ''" @click="save()">
          {{ $t("generic.lang_apply") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
    dataLayout: {
      type: String,
      default: "normal",
    },
    rules: {
      type: Array,
      default: () => [],
    },
    customClass: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: true,
    },
    left: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },
    bottom: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    prependInnerIcon: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "date",
    },
    showCurrent: {
      type: Boolean,
      default: true,
    },
    min: {
      type: String,
      default: "",
    },
    max: {
      type: String,
      default: "2030-01-01",
    },
    noTitle: {
      type: Boolean,
      default: true,
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    menu: false,
    date: "",
    time: "",
    oldVal: "",
    dateTime: "",
    tab:0,
  }),
  watch: {
    menu(val) {
      if (val == true) {
        this.tab = 0;
        this.oldVal = this.value;
        if(this.isValideDateTime(this.dateTime) === true){
          this.date = this.value.split(' ')[0];
          this.time = this.value.split(' ')[1];
        }
      }
    },
    date(val) {
      this.time = val? this.time || '00:00' : "";
      this.dateTime = val? val + " " + this.time : "";
      this.tab = 1;
    },
    time(val) {
      this.dateTime = val? this.date + " " + val : "";
    },
    dateTime(val){
      this.$emit("input", val);
    }
  },
  methods: {
    isValideDateTime(value) {
      // Regular expression to match the format YYYY-MM-DD HH:mm
      const regex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/;

      // First, check if the input matches the required format
      if (!regex.test(value)) {
        return this.$t("generic.lang_invalidFormat").replace("{format}", "YYYY-MM-DD HH:mm");
      }

      // If the format is correct, check if it's a valid date and time
      const [datePart, timePart] = value.split(' ');
      const [year, month, day] = datePart.split('-').map(Number);
      const [hour, minute] = timePart.split(':').map(Number);

      // Create a Date object and check if it's valid
      const date = new Date(year, month - 1, day, hour, minute);

      if (
        date.getFullYear() !== year ||
        date.getMonth() + 1 !== month ||
        date.getDate() !== day ||
        date.getHours() !== hour ||
        date.getMinutes() !== minute
      ) {
        return this.$t("generic.lang_invalidDateOrTime");
      }

      // If all checks pass, the input is valid
      return true;
    },
    close() {
      this.time = this.oldVal?.split(' ')[1] || '';
      this.date = this.oldVal?.split(' ')[0] || '';
      this.menu = false;
    },
    save() {
      this.$emit("input", this.dateTime);
      this.menu = false;
    },
    clear(){
      this.date = "";
      this.time = "";
    }
  },
};
</script>
